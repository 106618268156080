


































import { Component, Prop, Vue } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import ArrowDown from '@/app/ui/assets/CampaignRamadhan/arrow_down_icon.vue'

@Component({
  components: {
    Button,
    ArrowDown,
  },
})
export default class DropdownButton extends Vue {
  @Prop({ default: false }) private buttonList!: Array<string>
  @Prop({ default: false }) private disabled!: boolean

  isVisible = false

  private onClick(): void {
    this.isVisible = !this.isVisible
  }

  private onClickAction(index: number): void {
    this.$emit('onClickAction', index)
  }
}
