import { CreateManageCourierDedicatedRequestInterface } from '../contracts/ManageCourierDedicatedRequest'

export class ManageCourierDedicatedApiRequest
  implements CreateManageCourierDedicatedRequestInterface {
  private area_name?: string
  private geo_address_id?: Array<number>
  private courier_mp?: Array<number>
  private courier_ca?: Array<number>
  private courier_client?: Array<number>
  private offer_type?: string
  private is_active?: boolean

  constructor(
    areaName?: string,
    geoAddressId?: Array<number>,
    courierMp?: Array<number>,
    courierCa?: Array<number>,
    courierClient?: Array<number>,
    offerType?: string,
    isActive?: boolean
  ) {
    this.area_name = areaName
    this.geo_address_id = geoAddressId
    this.courier_mp = courierMp
    this.courier_ca = courierCa
    this.courier_client = courierClient
    this.offer_type = offerType
    this.is_active = isActive
  }

  public toPayload(): string {
    return JSON.stringify({
      area_name: this.area_name,
      geo_address_id: this.geo_address_id,
      courier_mp: this.courier_mp,
      courier_ca: this.courier_ca,
      courier_corporate: this.courier_client,
      offer_type: this.offer_type,
      is_active: this.is_active,
    })
  }
}
