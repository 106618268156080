export enum EnumStatus {
  AKTIF = 'AKTIF',
  NONAKTIF = 'NONAKTIF'
}

export enum EnumOfferMethodType {
  AUTO_ASSIGN = 'AUTO ASSIGN',
  OFFER = 'OFFER'
}

export enum EnumCourierType {
  MARKETPLACE = 'courierMP',
  CUSTOMER = 'courierCA',
  CLIENT = 'courierClient'
}

export enum EnumCourierTypeParams {
  POS = 'POS',
  KVP = 'KVP'
}

export const ManageCourierDedicatedStatus = {
  ...EnumStatus,
}

export enum EnumErrorMessage {
  AREA_NAME_EXIST = 'Area Have been Used'
}

export interface IStyle {
  minWidth: string
  maxWidth: string
}

export interface IDataCell {
  value: number | string | boolean | string[]
  customStyle: IStyle
}

export interface IHeaderCell {
  title: number | string
  customStyle: IStyle
}

export interface IOptions {
  label: string
  value: string | number | boolean | undefined
}

export interface IParams {
  page: number
  per_page: number
  sort: IOptions
  offer: IOptions
  status: IOptions
  keyword: string
}

export interface ICourierDedicatedParams {
  page: number
  per_page: number
  sort: IOptions
  keyword: string
}

export interface ICourierParams {
  page: number
  perPage: number
  q: string
  sortBy: string
  driverType: string
  courierStatus: string
}

export interface IGeoAddressParams {
  search: string
}

export interface IForm {
  areaName: string
  location: Array<number>
  courierMP: Array<IOptions>
  courierCA: Array<IOptions>
  courierClient: Array<IOptions>
  offerMethod: IOptions
  status: IOptions
}

export interface IFormDetail extends Omit<IForm, 'status' | 'offerMethod'> {
  offerMethod: string
  status: string
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
}

export interface IHistoryFields {
  name: string
  formKey: string
}

export interface CreatePayload {
  area_name: string;
  geo_address_id: Array<number>;
  courier_mp: Array<number>;
  courier_ca: Array<number>;
  courier_client: Array<number>;
  offer_type: string;
  is_active: boolean;
}

export interface UpdatePayload extends CreatePayload {
  id: number
}

export const offerMethodOptions: Array<IOptions> = [
  { label: 'Semua', value: '' },
  { label: EnumOfferMethodType.AUTO_ASSIGN, value: EnumOfferMethodType.AUTO_ASSIGN },
  { label: EnumOfferMethodType.OFFER, value: EnumOfferMethodType.OFFER },
]

export const statusOptions: Array<IOptions> = [
  { label: 'Semua', value: undefined },
  { label: EnumStatus.AKTIF, value: true },
  { label: EnumStatus.NONAKTIF, value: false },
]

export const listStatusOptions: Array<IOptions> = [
  { label: 'Semua', value: '' },
  { label: EnumStatus.AKTIF, value: 'ACTIVE' },
  { label: EnumStatus.NONAKTIF, value: 'INACTIVE' },
]

export const sortOptions: Array<IOptions> = [
  { label: 'A-Z', value: 'ASC' },
  { label: 'Z-A', value: 'DESC' },
]
