




















































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DropdownButton from '../../components/DropdownButton/index.vue'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import controller from '@/app/ui/controllers/ManageCourierDedicatedController'
import Badge from '@/app/ui/components/Badge/index.vue'
import {
  IDataCell,
  IHeaderCell,
  IOptions,
  IParams,
  listStatusOptions,
  ManageCourierDedicatedStatus,
  offerMethodOptions,
  sortOptions,
} from '@/app/infrastructures/misc/Constants/manageCourierDedicated'
import { Utils } from '@/app/infrastructures/misc'
import {
  CourierDedicated,
  GeoAddress,
} from '@/domain/entities/ManageCourierDedicated'
import { Location } from 'vue-router'

interface ILocation {
  id: number
  name: string
  children: Array<ILocation>
  isSelected: () => boolean
}

interface IGeoAddress {
  id: number
  name: string
  children: Array<IGeoAddress>
}

interface IManageCourierDedicated {
  areaId: number
  areaName: string
  geoAddress: Array<ILocation>
  courierMp: Array<CourierDedicated>
  courierCa: Array<CourierDedicated>
  courierClient: Array<CourierDedicated>
  offerType: string
  isActive: boolean
  updatedAt: string
  createdAt: string
}

@Component({
  components: {
    Badge,
    Button,
    TextInput,
    TrashIcon,
    DataTableV2,
    PaginationNav,
    DropdownSelect,
    DropdownButton,
    ModalConfirm,
    LoadingOverlay,
  },
})
export default class AreaDedicatedListPage extends Vue {
  controller = controller
  isDropdownButtonVisible = false
  modalConfirmVisible = false
  deleteId = ''

  statusOptions: Array<IOptions> = listStatusOptions
  offerOptions: Array<IOptions> = offerMethodOptions
  sortOptions: Array<IOptions> = sortOptions
  geoAddressData: Array<GeoAddress> = []

  parameters: IParams = {
    page: 1,
    per_page: 10,
    status: this.statusOptions[0],
    offer: this.offerOptions[0],
    sort: this.sortOptions[0],
    keyword: '',
  }

  geoAddressParameters: Record<string, string> = {
    search: '',
  }

  headers: Array<string | IHeaderCell> = [
    this.headerCellMapper('No.', '50px'),
    this.headerCellMapper('Area', '160px'),
    this.headerCellMapper('Wilayah', '300px'),
    this.headerCellMapper('Kurir Dedicated MP', '240px'),
    this.headerCellMapper('Kurir Dedicated CA', '240px'),
    this.headerCellMapper('Kurir Dedicated Client', '240px'),
    this.headerCellMapper('Metode Penawaran', '180px'),
    this.headerCellMapper('Status', '140px'),
    this.headerCellMapper('Terakhir Diubah', '180px'),
    this.headerCellMapper('Dibuat Tanggal', '180px'),
    this.headerCellMapper('Aksi', '400px'),
  ]

  manageCourierDedicatedData: Array<
    Array<string | Array<string> | IDataCell>
  > = []

  created(): void {
    this.fetchGeoAddress()
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      per_page: this.parameters.per_page,
      keyword: this.parameters.keyword,
      status: <string>this.parameters.status.value,
      offer_type: <string>this.parameters.offer.value,
      sort_by: <string>this.parameters.sort.value,
    }
  }

  get geoAddressParams(): Record<string, string> {
    return this.geoAddressParameters
  }

  private fetchManageCourierDedicated(reset?: boolean): void {
    if (reset) this.parameters.page = 1
    controller.getAll(this.params)
  }

  private fetchGeoAddress(): void {
    controller.getGeoAddress(this.geoAddressParams)
  }

  private setBadgeStyle(status: string): string {
    if (status.toUpperCase() === ManageCourierDedicatedStatus.AKTIF) {
      return 'success'
    } 
  
    return 'error'
  }

  private routeTo(id: string, routeName: string): Location {
    return {
      name: routeName,
      params: { id: id }
    }
  }

  private onConfirmDelete(id: string): void {
    this.deleteId = id
    this.modalConfirmVisible = true
  }

  private onCancelDelete(): void {
    this.deleteId = ''
    this.modalConfirmVisible = false
  }

  private onDelete(): void {
    this.modalConfirmVisible = false

    if (this.deleteId) {
      controller.deleteManageCourierDedicated(Number(this.deleteId))
    }
  }

  private onSearchFilter(): void {
    if (
      (this.parameters.keyword && this.parameters.keyword.length > 2) ||
      !this.parameters.keyword
    ) {
      this.onFilterList()
    }
  }

  private onFilterList = Utils.debounce(() => {
    this.fetchManageCourierDedicated(true)
  }, 500)

  private tableCellMapper(
    value: string | number | boolean | string[],
    colWidth: string
  ): IDataCell {
    return {
      value: value,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private setCourierData(courierList: Array<CourierDedicated>): Array<string> {
    return courierList.map(courier => {
      return `[${courier.courierId}] ${courier.name} (${courier.courierType}) ${courier.phone}`
    })
  }

  private locationMapper(
    data: GeoAddress,
    selectedIds: Array<number>
  ): ILocation {
    return {
      id: data.geoAddressId || NaN,
      name: data.name || '',
      children: data.children
        ? data.children.map(child => {
            return this.locationMapper(child, selectedIds)
          })
        : [],
      isSelected(): boolean {
        if (
          data.geoAddressId &&
          selectedIds.includes(Number(data.geoAddressId))
        ) {
          return true
        }

        return false
      },
    }
  }

  private getName(data: Array<ILocation>): Array<string> {
    let nameList: Array<string> = []

    data.forEach(item => {
      if (item.isSelected()) {
        nameList.push(item.name)
      } else {
        nameList.push(...this.getName(item.children))
      }
    })

    return nameList
  }

  @Watch('controller.dataManageCourierDedicatedList')
  setManageCourierDedicatedData(data: IManageCourierDedicated[]): void {
    this.manageCourierDedicatedData = data.map((item, index) => {
      let locationNames: Array<string> = []

      if (item.geoAddress) {
        let locationIds: Array<number> = []

        item.geoAddress.forEach(location => {
          if (location.id) {
            locationIds.push(location.id)
          }
        })

        if (locationIds.length !== 0) {
          const locData = this.geoAddressData.map(location => {
            return this.locationMapper(location, locationIds)
          })

          locationNames = this.getName(locData)
        }
      }

      return [
        this.tableCellMapper(
          (this.parameters.page - 1) * this.parameters.per_page + index + 1,
          '50px'
        ),
        this.tableCellMapper(item.areaName || '-', '160px'),
        this.tableCellMapper(
          JSON.stringify(locationNames),
          '300px'
        ),
        this.tableCellMapper(item.courierMp ? this.setCourierData(item.courierMp) : [], '240px'),
        this.tableCellMapper(item.courierCa ? this.setCourierData(item.courierCa) : [], '240px'),
        this.tableCellMapper(item.courierClient ? this.setCourierData(item.courierClient) : [], '240px'),
        this.tableCellMapper(item.offerType
          ? Utils.toCapitalize(item.offerType).replace(' ', '-')
          : '', '180px'),
        this.tableCellMapper(item.isActive
          ? ManageCourierDedicatedStatus.AKTIF
          : ManageCourierDedicatedStatus.NONAKTIF, '140px'),
        this.tableCellMapper(item.updatedAt
          ? Utils.formatDateWithIDLocale(
              String(item.updatedAt),
              'dddd, DD MMM YYYY'
            )
          : '', '180px'),
        this.tableCellMapper(item.createdAt
          ? Utils.formatDateWithIDLocale(
              String(item.createdAt),
              'dddd, DD MMM YYYY'
            )
          : '', '180px'),
        this.tableCellMapper(String(item.areaId), '400px'),
      ]
    })
  }

  @Watch('controller.dataGeoAddressList')
  setGeoAddressData(data: IGeoAddress[]): void {
    this.geoAddressData = data
    this.fetchManageCourierDedicated()
  }

  @Watch('controller.isDeleteManageCourierDedicatedSuccess')
  onCheckDelete(data: boolean): void {
    this.deleteId = ''

    if (data) {
      this.$notify({
        title: 'Area Berhasil Dihapus',
        text: 'Area Dedicated Berhasil Dihapus',
        type: 'success',
        duration: 5000,
      })

      controller.setErrDelete('')
      controller.setIsDeleteManageCourierDedicatedSuccess(false)
      this.fetchManageCourierDedicated(true)
    }
  }
}
