import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import { ManageCourierDedicatedPresenter } from '../presenters/ManageCourierDedicatedPresenter'
import {
  CourierDedicatedList,
  CourierDedicatedListItem,
  GeoAddress,
  ManageCourierDedicated,
  ManageCourierDedicatedDetail,
  ManageCourierDedicateds,
} from '@/domain/entities/ManageCourierDedicated'
import { ManageCourierDedicatedApiRequest } from '@/data/payload/api/ManageCourierDedicatedRequest'
import {
  CreatePayload,
  EnumErrorMessage,
  UpdatePayload,
} from '@/app/infrastructures/misc/Constants/manageCourierDedicated'

export interface ManageCourierDedicatedState {
  isLoading: boolean
  isAreaNameExist: boolean
  dataManageCourierDedicatedList: ManageCourierDedicated[]
  dataManageCourierDedicatedDetail: ManageCourierDedicatedDetail
  dataGeoAddressList: GeoAddress[]
  paginationData: Pagination
  maxConfig: number
  dataCourierDedicatedList: CourierDedicatedListItem[]
  paginationDataCourierDedicated: Pagination
  errDelete: string
  isDeleteManageCourierDedicatedSuccess: boolean
  isSuccessEditManageCourierDedicated: boolean
  isSuccessCreateManageCourierDedicated: boolean
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'manage-courier-dedicated',
})
class ManageCourierDedicatedController extends VuexModule
  implements ManageCourierDedicatedState {
  public presenter: ManageCourierDedicatedPresenter = container.resolve(
    ManageCourierDedicatedPresenter
  )
  public isLoading = false
  public isAreaNameExist = false
  public dataManageCourierDedicatedList = [new ManageCourierDedicated()]
  public dataManageCourierDedicatedDetail = new ManageCourierDedicatedDetail()
  public dataGeoAddressList = [new GeoAddress()]
  public paginationData = new Pagination()
  public maxConfig = 0
  public dataCourierDedicatedList = [new CourierDedicatedListItem()]
  public paginationDataCourierDedicated = new Pagination()
  public errDelete = ''
  public isDeleteManageCourierDedicatedSuccess = false
  public isSuccessEditManageCourierDedicated = false
  public isSuccessCreateManageCourierDedicated = false

  @Action({ rawError: true })
  public getAll(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        if (res.data){
          
          this.setDataManageCourierDedicatedList(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Manage Courier Dedicated List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getAllCourierDedicated(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAllCourierDedicated(formattedParams)
      .then(res => {
        if (res.data) this.setDataCourierDedicatedList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Courier Dedicated List Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getGeoAddress(params: Record<string, string | number>): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getGeoAddress(formattedParams)
      .then(res => {
        if (res) this.setDataGeoAddressList(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Geo Address Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public deleteManageCourierDedicated(id: number): void {
    this.setLoading(true)
    this.presenter
      .delete(id)
      .then(() => {
        this.setIsDeleteManageCourierDedicatedSuccess(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Area Gagal Dihapus',
          text: 'Area Dedicated Gagal Dihapus',
          type: 'error',
          duration: 5000,
        })
        this.setErrDelete(error.error.message.en)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetail(id: number): void {
    this.setLoading(true)

    this.presenter
      .get(id)
      .then(res => {
        if (res) {
          this.setDataManageCourierDedicatedDetail(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Manage Courier Dedicated Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public create(payload: CreatePayload): void {
    this.setLoading(true)
    this.presenter
      .create(
        new ManageCourierDedicatedApiRequest(
          payload.area_name,
          payload.geo_address_id,
          payload.courier_mp,
          payload.courier_ca,
          payload.courier_client,
          payload.offer_type,
          payload.is_active
        )
      )
      .then(() => {
        this.setIsSuccessCreateManageCourierDedicated(true)
      })
      .catch(error => {
        if (
          error.error.message.en.toUpperCase() ===
          EnumErrorMessage.AREA_NAME_EXIST.toUpperCase()
        ) {
          this.setIsAreaNameExist(true)
        }

        Vue.notify({
          title: 'Create Manage Courier Dedicated Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsSuccessCreateManageCourierDedicated(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getMaxConfig(): void {
    this.setLoading(true)

    this.presenter
      .getMaxConfig()
      .then(res => {
        if (res) {
          this.setMaxConfig(res)
        }
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Max Config Courier Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public update(payload: UpdatePayload) {
    this.setLoading(true)
    this.presenter
      .update(
        payload.id,
        new ManageCourierDedicatedApiRequest(
          payload.area_name,
          payload.geo_address_id,
          payload.courier_mp,
          payload.courier_ca,
          payload.courier_client,
          payload.offer_type,
          payload.is_active
        )
      )
      .then(() => {
        this.setIsSuccessEditManageCourierDedicated(true)
      })
      .catch(error => {
        Vue.notify({
          title: 'Update Area Dedicated Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setIsSuccessEditManageCourierDedicated(false)
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  public setIsAreaNameExist(bool: boolean): void {
    this.isAreaNameExist = bool
  }

  @Mutation
  private setDataManageCourierDedicatedList(
    list: ManageCourierDedicateds
  ): void {
    this.dataManageCourierDedicatedList = <ManageCourierDedicated[]>list.data
    this.paginationData = <Pagination>list.pagination
  }

  @Mutation
  private setDataCourierDedicatedList(
    list: CourierDedicatedList
  ): void {
    this.dataCourierDedicatedList = <CourierDedicatedListItem[]>list.data
    this.paginationDataCourierDedicated = <Pagination>list.pagination
  }

  @Mutation
  private setDataGeoAddressList(list: Array<GeoAddress>): void {
    this.dataGeoAddressList = <GeoAddress[]>list
  }

  @Mutation
  private setDataManageCourierDedicatedDetail(
    data: ManageCourierDedicatedDetail
  ): void {
    this.dataManageCourierDedicatedDetail = data
  }

  @Mutation
  private setMaxConfig(amount: number) {
    this.maxConfig = amount
  }

  @Mutation
  public setErrDelete(msg: string) {
    this.errDelete = msg
  }

  @Mutation
  public setIsDeleteManageCourierDedicatedSuccess(bool: boolean) {
    this.isDeleteManageCourierDedicatedSuccess = bool
  }

  @Mutation
  public setIsSuccessEditManageCourierDedicated(bool: boolean) {
    this.isSuccessEditManageCourierDedicated = bool
  }

  @Mutation
  public setIsSuccessCreateManageCourierDedicated(bool: boolean) {
    this.isSuccessCreateManageCourierDedicated = bool
  }
}

export default getModule(ManageCourierDedicatedController)
